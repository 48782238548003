<template>
  <div class="containar">
    <!-- 轮播 -->
    <Slide :slideshow="slideshow" />
    <div class="main">
      <div class="section">
        <el-card class="box-card">
          <h3 class="title">{{ notice.title }}</h3>
          <div class="content richText ql-editor" v-html="notice.content"></div>
          <p class="accessory" v-if="notice.attachment.length > 0">
            <a v-for="(item, i) in notice.attachment" :key="i" :href="item"
              >下载附件{{i+1}}</a
            >
          </p>
          <div class="time">发布时间：{{ notice.releaseTime }}</div>
        </el-card>
      </div>
      <div class="aside">
        <HeatNotice :heatNoticeArr="heatNoticeArr" />
      </div>
    </div>
  </div>
</template>

<script>
import Slide from "@/components/slide";
import HeatNotice from "./components/heatNotice.vue";
import {get} from "@/utils/local.js"
import { noticeSlide_api, getHeatNoticeList_api } from "@/api/notice.js";
export default {
  components: {
    Slide,
    HeatNotice,
  },
  data() {
    return {
      slideshow: [],
      heatNoticeArr: [], // 重要公告数据
      notice:{}, // 公告详情
    };
  },
  methods: {
    // 获取轮播数据
    async getSlideImg() {
      let { data, code } = await noticeSlide_api({
        tenantId: this.$tenantId,
      });
      if (code == 200) this.slideshow = data;
    },
    // 获取重要通知
    async getHeatNoticeList() {
      let { code, data } = await getHeatNoticeList_api({
        tenantId: this.$tenantId,
        size: 10,
      });
      if (code == 200) {
        this.heatNoticeArr = data;
      }
    },
    // 获取并处理公告详情
    getNotice(){
      this.notice = get("notice")
      if (typeof this.notice.attachment === "string") this.notice.attachment = this.notice.attachment.split(",");
      this.notice.attachment.forEach((item, i) => {
        if (!item) this.notice.attachment.splice(i, 1);
      });
    },
  },
  watch:{
    $route:function(){
      this.getNotice()
    }
  },
  created() {
    this.getSlideImg();
    this.getHeatNoticeList();
    this.getNotice()
  },
};
</script>

<style lang="less" scoped>
@title-color: #0079fe;
@btn-color: #f66866;
.main {
  margin: 100px auto;
  display: flex;
  .section {
    flex: 1;
    margin-right: 30px;
    .title {
      text-align: center;
      margin: 20px 0;
      font-weight: 700;
    }
    .content {
      line-height: 1.5em;
    }
    .accessory{
      margin-top:20px;
      a{
        margin-right:20px;
        color:@title-color
      }
    }
    .time{
      margin:20px 0;
      color:#888;
    }
  }
  .aside {
    width: 400px;
  }
}
</style>